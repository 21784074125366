import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Configurator } from '@gcc/configurator';
import cookieUtils from 'cookie-utils';
import Layout from '../templates/configuratorTemplates/Layout';
import useDynamicTabTitle from '../hooks/useDynamicTabTitle';
import {
  productForDisplaySelector,
  dynamicTabTitleSelector,
  productInfoNonDefaultSelector,
} from '../selectors';
import useQuantity from '../hooks/useQuantity';
import {
  useProductConfiguratorGraphDataChange,
  useProductConfiguratorMounted,
  useProductConfiguratorAttributes,
  useProductConfiguratorErrors,
  useProductConfiguratorIsSpecialProduct,
  useProductConfiguratorSubscribe,
  useProductConfiguratorAssebleItemAt,
  useProductConfiguratorMapSkus,
  useProductConfiguratorSetZipCode,
  useProductConfiguratorFireRules,
} from '../hooks/useProductConfigurator';
import {
  getProductConfiguratorLifeCycleEvents,
  getProductConfiguratorMemoProperties,
} from '../utils/getProductConfigurator';
import { ProductConfiguratorSkeleton } from './ProductConfiguratorSkeleton';
import {
  getItemDetails,
  getProductConfiguratorData,
  getProductConfiguratorProperties,
  customATC,
} from '../utils/product-utils';
import { productConfiguratorDataModel } from '../utils/productConfiguratorDataModel';

import '../pipfigurator.scss';

const ProductConfigurator = (props) => {
  const {
    onLoadSuccess,
    onMount,
    config,
    thdStoreInfo,
    onSaveConfiguration,
    onChangeGraphData,
    afterRulesExecuted,
    afterChoiceSelectionUpdate,
    sku,
    skuType,
    bypassSkuMap,
    homeDelivery,
    attributes,
    dynamicTabTitle,
    storeNumber,
    productInfo,
    cartProduct,
    skuMap,
    itemIdForDisplay,
    deliveryTime,
  } = props;

  global.cookieUtils = cookieUtils;

  const { productConfiguratorMounted } = useProductConfiguratorMounted(props.isPreview);

  const {
    err,
    assembleItemAtStore,
    assembleItemAtHome,
    productAddOns,
    configuratorSubscribe,
    frequency,
    qty,
    method,
    fireRules,
  } = getProductConfiguratorLifeCycleEvents();

  const {
    items,
    isPipFlip,
    setZipCode,
    configuratorSettings,
    data,
    fulfillment,
    type,
    fulfillmentMethod,
    setIsMajorAppliance,
    showOptions,
  } = getProductConfiguratorProperties({
    cartProduct,
    productInfo,
    config,
    skuMap,
    thdStoreInfo,
    onLoadSuccess,
    onMount,
    onSaveConfiguration,
    afterRulesExecuted,
    afterChoiceSelectionUpdate,
    sku,
    skuType,
    bypassSkuMap,
    itemIdForDisplay,
    method,
    attributes,
  });

  const {
    methodData,
    fulfillmentData,
    attributesData,
    assembleItemAtHomeData,
    productAddOnsData,
    qtyData,
    productInfoData,
    assembleItemAtStoreData,
    configurationErrorData,
    fireRulesData,
    cart,
  } = getProductConfiguratorMemoProperties({
    fireRules,
    method,
    type,
    attributes,
    assembleItemAtHome,
    productAddOns,
    qty,
    productInfo,
    cartProduct,
    assembleItemAtStore,
    skuMap,
  });

  let itemDetails = getItemDetails({
    method,
    fulfillmentMethod,
    thdStoreInfo,
    cart,
    storeNumber,
    qty,
    productInfo,
  });

  let { newData, parsedData, parsedDeliveryData } = getProductConfiguratorData({
    data,
    cartProduct,
    itemDetails,
    items,
    fulfillment,
    deliveryTime,
  });

  useProductConfiguratorSetZipCode({ setZipCode, thdStoreInfo });

  useProductConfiguratorFireRules({ fireRulesData });

  useProductConfiguratorIsSpecialProduct({ skuMap, customATC });

  useProductConfiguratorGraphDataChange({
    onChangeGraphData,
    fulfillmentMethod,
    fulfillment,
    fulfillmentData,
    methodData,
    productInfoData,
    cart,
    attributesData,
    cartProduct,
    skuMap,
    method,
    thdStoreInfo,
    itemDetails,
    qty,
    deliveryTime,
    items,
    storeNumber,
    newData,
    parsedData,
    parsedDeliveryData,
  });

  useProductConfiguratorMapSkus({ productInfoData, productInfo, config, skuMap, cartProduct });

  useProductConfiguratorSubscribe({ configuratorSubscribe, frequency, onChangeGraphData, newData });

  useProductConfiguratorAssebleItemAt({
    assembleItemAtStoreData,
    assembleItemAtHomeData,
    productAddOnsData,
    newData,
    assembleItemAtHome,
    assembleItemAtStore,
    onChangeGraphData,
  });

  useProductConfiguratorErrors({ skuMap, configurationErrorData, err });

  useProductConfiguratorAttributes({
    attributes,
    setIsMajorAppliance,
    onChangeGraphData,
    parsedData,
  });

  useQuantity(cart, skuMap, items, newData, onChangeGraphData, deliveryTime, qtyData, qty);

  useDynamicTabTitle(dynamicTabTitle);

  if (!productConfiguratorMounted) {
    return <ProductConfiguratorSkeleton />;
  }

  return (
    <div className="product-configurator">
      <Configurator settings={configuratorSettings}>
        <Layout homeDelivery={homeDelivery} isPipFlip={isPipFlip} showOptions={showOptions} />
      </Configurator>
    </div>
  );
};

ProductConfigurator.propTypes = {
  onMount: PropTypes.func.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  homeDelivery: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

ProductConfigurator.defaultProps = {};

ProductConfigurator.displayName = 'ProductConfigurator';

ProductConfigurator.dataModel = productConfiguratorDataModel;

const mapStateToProps = (state) => {
  const dynamicTabTitle = dynamicTabTitleSelector(state);
  const {
    skuMap: { sku, skuType },
    configurator: {
      ajaxStatus: { isInitialized },
    },
    choiceAndSkuAvailability: { choiceAvailability },
  } = state;
  const { bypassSkuMap } = state.product.details.attributes || '';
  const { itemId } = productForDisplaySelector(state);
  const productInfoNonDefault = productInfoNonDefaultSelector(state);
  const { hasServiceAddOns } = state.productInfo.base || false;

  return {
    config: state.config,
    thdStoreInfo: state.thdStoreInfo,
    sku,
    skuType,
    choiceAvailability,
    isInitialized,
    bypassSkuMap,
    dynamicTabTitle,
    itemIdForDisplay: itemId,
    hasServiceAddOns,
    productInfoNonDefault,
  };
};

export default connect(mapStateToProps)(ProductConfigurator);
