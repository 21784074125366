import merge from 'lodash/merge';
import { isHomeDelivery } from '../../utils/isHomeDelivery';
import { UPDATE_CART_PRODUCT } from '../actionTypes';
import choiceAndSkuAvailability from '../../actions/outOfStock/choiceAndSkuAvailability';
import { cartProductSelector } from '../../selectors';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_DATA} from '../hydration/constants';

const addGccConfigurationToCart = (configurationGuid, items, cartOptions) => (dispatch, getState) => {
  const {
    config: {
      partnerProductId,
    },
    thdStoreInfo: {
      storeNumber: localStoreId,
      deliveryZip,
    },
  } = getState();
  const product = cartProductSelector(getState());

  const itemDetails = [
    cartOptions?.storeId && cartOptions?.fulfillmentMethod && !isHomeDelivery(cartOptions?.fulfillmentMethod) ? {
      itemId: partnerProductId.toString(),
      configurationId: configurationGuid,
      quantity: product?.configuration?.cart?.[0]?.quantity || 1,
      fulfillmentMethod: cartOptions.fulfillmentMethod,
      fulfillmentLocation: cartOptions.storeId,
    } : {
      itemId: partnerProductId.toString(),
      configurationId: configurationGuid,
      quantity: product?.configuration?.cart?.[0]?.quantity || 1,
      fulfillmentLocation: deliveryZip,
      fulfillmentMethod: cartOptions.fulfillmentMethod,
      storeId: localStoreId,
      zipCode: deliveryZip,
    },
  ];

  delete product?.configuration?.cart;
  delete product?.configuration?.items;

  const configuration = {
    deliveryTime:  product?.configuration?.deliveryTime,
    cart: itemDetails,
    items: items
  };

  const parsedData = merge(
    {},
    { product },
    { product: { configuration } },
  );

  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, { configuratorData: parsedData });

  return Promise.resolve(dispatch({
    type: UPDATE_CART_PRODUCT,
    data: parsedData,
  }))
  .then(() => Promise.resolve(dispatch(choiceAndSkuAvailability({ isStupidProduct: true, overrideStoreNumber: cartOptions.storeId }))))
  .then(() => Promise.resolve(itemDetails[0]));

};

export default addGccConfigurationToCart;